


























































import './scss/PopupTaskDetails.scss';
import { formatDate } from '@utility/formatDate';
import { Component, Prop, Watch, Vue, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IChangeTaskStatePostData, ITakeTaskPostData } from '@store/modules/project-tasks/interfaces/ApiRequests';
import { ITask } from '@store/modules/project-tasks/interfaces/Interfaces';
import { ProjectTasksActions } from '@store/modules/project-tasks/Types';
import { ProjectActions, ProjectMutations } from '@store/modules/project/Types';
import { IProject } from "@store/modules/project/Interfaces";
import { IUserState } from "@store/modules/user/Interfaces";

const NSProject = namespace('storeProject');
const NSProjectTasks = namespace('storeProjectTasks');
const NSUser = namespace('storeUser');

@Component({
    name: 'PopupTaskDetails',

    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
        BaseTextarea: () => import('@components/BaseInputs/BaseTextarea.vue'),
        BaseFileInput: () => import('@components/BaseFileInput'),
        TaskDetailsInfo: () => import('@components/TaskDetailsInfo/TaskDetailsInfo.vue'),
    },
})
export default class PopupTaskDetails extends Vue {
    $refs!: { basePopup: any }

    @Prop({ default: false }) popupOpen = false;
    @Prop({ default: false }) task!: ITask;
    @Prop({default: false}) disabledEscKey: boolean = false;
    @Prop({default: false}) onlyInfo!: boolean;
    @Prop({ default: false }) waitingSaveSubtask!: boolean;
    @Prop({ default: '' }) captionFieldFile!: string;

    @NSProject.Getter('projectId') projectId!: number;
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSUser.State((state: IUserState) => state.userInfo.id) userUuid!: string;
    @NSProject.Action(ProjectActions.A_READ_OBJECTS) readObjects!: (payload) => Promise<void>
    @NSProject.Mutation(ProjectMutations.M_SET_NEW_TASKS) setNewTasks!: (payload) => Promise<void>
    @NSProjectTasks.Action(ProjectTasksActions.A_EDIT_TASK) editTask!: (data) => Promise<void>

    files: File[] = [];
    newTaskComment: string = '';
    commentSaved = false;
    saveBtnDisabled: boolean = false;

    @Emit('send-comment')
    emitSendComment(successSendText): string {
        return successSendText;
    }

    get taskComment() {
        if (this.task && this.task.comment && this.task.comment.indexOf('\n') !== -1) {
            return `<p>${this.task.comment.replaceAll('\n', '</p><p>')}</p>`;
        }
        return this.task.comment;
    }

    get isOnCheckTask() {
        return this.task.state.name.toLowerCase() === 'на проверке';
    }

    get executedByUser() {
        return this.task.executor?.uuid === this.userUuid;
    }

    get createByUser() {
        return this.task.author?.uuid === this.userUuid;
    }

    get popupType() {
        if (this.task.state?.name ==='Новое') {
            return 'take-task';
        } else if (this.executedByUser && this.task.state?.name === 'В работе') {
            return 'complete-task';
        } else if (this.createByUser && this.task.state?.name === 'На проверке') {
            return 'accept-task';
        }
        return 'task-info';
    }

    get commentLabel() {
        switch (this.popupType) {
            case 'take-task':
                return 'Комментарий к задаче';
            case 'complete-task':
                return 'Результат выполнения задания';
            case 'accept-task':
                return 'Результат проверки задания';
            default:
                return '';
        }
    }

    get textActionSuccess() {
        switch (this.popupType) {
            case 'complete-task':
                return 'Отправить';
            case 'take-task':
                return 'Принять';
            case 'accept-task':
                return 'Принять';
            default:
                return '';
        }
    }

    @Watch('popupOpen')
    watchPopupOpen() {
        if (this.projectData.newTasks && this.projectData.newTasks.includes(this.task.uuid)) {
            this.readObjects({
                appId: this.projectId,
                ids: [this.task.uuid],
                type: 'task',
            }).then(() => {
                if (this.projectData && this.projectData.newTasks) {
                    const newTaskIds = this.projectData.newTasks.filter(taskId => taskId !== this.task.uuid);
                    this.setNewTasks(newTaskIds);
                }
            })
        }
        if (this.popupOpen) {
            this.$refs.basePopup.openPopup();
            this.newTaskComment = this.task?.commentTask;
        }
        else this.$refs.basePopup.closePopup();
    }

    emitClose() {
        this.$emit('close');
        this.resetData();
    }

    closePopup() {
        this.emitClose();
        this.resetData();
    }

    changeFiles(files: File[]) {
        this.files = files;
    }

    sendTaskComment() {
        this.saveBtnDisabled = true;
        this.editTask({
            appId: this.projectId,
            tasks: [{
                id: this.task.uuid,
                comment: this.newTaskComment,
            }],
        }).then(() => {
            this.commentSaved = true;
            this.emitSendComment('Комментарий успешно сохранен');
        }).finally(() => this.saveBtnDisabled = false);
    }

    successAction() {
        switch (this.popupType) {
            case 'take-task':
                this.$emit('take-task', this.formTakingTaskPostData());
                break;
            case 'complete-task':
                this.$emit('complete-task', this.formChangeTaskStatePostData('complete'));
                break;
            case 'accept-task':
                this.$emit(
                    'check-task',
                    { taskFormData: this.formChangeTaskStatePostData('check'), confirmableAction: 'accept' }
                );
                break;
            default:
                return '';
        }
        this.emitClose();
        this.resetData();
    }

    returnTask() {
        this.$emit(
            'check-task',
            { taskFormData: this.formChangeTaskStatePostData('check'), confirmableAction: 'return' }
        );
        this.emitClose();
        this.resetData();
    }

    formTakingTaskPostData(): ITakeTaskPostData {
        return {
            appId: this.projectId,
            id: this.task.uuid,
            text: this.newTaskComment ?? '',
            takeDate: formatDate({ date: new Date(), format: 'yyyy-mm-ddThh:mm:ss' }),
            file: this.files[0] ?? '',
            fileFormat: '',
        }
    }

    formChangeTaskStatePostData(typeAction: string): IChangeTaskStatePostData {
        const output: IChangeTaskStatePostData = {
            appId: this.projectId,
            id: this.task.uuid,
            text: this.newTaskComment ?? '',
            file: this.files[0] ?? '',
            fileFormat: '',
        }
        if (typeAction === 'complete') {
            output.startDate = this.task.creationDate;
            output.finishDate = formatDate({ date: new Date(), format: 'yyyy-mm-ddThh:mm:ss' });
        } else if (typeAction === 'check') {
            output.checkDate = formatDate({ date: new Date(), format: 'yyyy-mm-ddThh:mm:ss' });
        }
        return output;
    }

    resetData() {
        this.files = [];
        this.newTaskComment = '';
        this.commentSaved = false;
    }
}
